<template>
	<div class="booth-selector">
		<div class="booth-selector-exhibitor" :class="{ 'is-image': !!exhibitor.logo }">
			<img v-if="exhibitor.logo && exhibitor.logo.url" :src="exhibitor.logo | imageUrl" :alt="exhibitor.title" />
			<h1 v-else>{{ exhibitor.title }}</h1>
		</div>
		<p>Please select one of the available booths below</p>
		<div class="booth-selector-wrapper">
			<ul :class="{ 'has-disclaimer': exhibitor.disclaimer }">
				<li v-for="booth in booths" :key="booth.id" class="booth-selector-booth">
					<a @click.prevent="goToBooth(booth)">
						<img
							v-if="(booth.logo && booth.logo.url) || (booth.splash_logo && booth.splash_logo.url)"
							:src="booth.splash_logo ? booth.splash_logo : booth.logo | imageUrl"
							:alt="booth.title"
						/>
						<h2 v-else>{{ booth.title }}</h2>
					</a>
				</li>
			</ul>
		</div>
		<div v-if="exhibitor.disclaimer" class="booth-selector-disclaimer">
			<VueMarkdown :source="exhibitor.disclaimer" :prerender="prerender" />
		</div>
	</div>
</template>

<script>
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';
	import ExternalDisclaimer from '@/components/ExternalDisclaimer';

	export default {
		name: 'BoothSelector',
		components: { VueMarkdown },
		props: {
			exhibitor: Object,
		},
		computed: {
			booths() {
				return this.exhibitor.booths;
			},
		},
		methods: {
			goToBooth(booth) {
				if (booth.external_booth_url) {
					this.$modal.show(
						ExternalDisclaimer,
						{
							url: booth.external_booth_url,
							target: true,
						},
						{
							classes: 'external-disclaimer',
							width: '90%',
							maxWidth: 550,
							height: 'auto',
							adaptive: true,
						}
					);
				} else {
					this.$router.push({ name: 'Booth', params: { url: booth.url } });
					this.$emit('close');
				}
			},
			prerender: prerender,
		},
	};
</script>
