<template>
	<div>
		<p>The following virtual exhibition booth has restricted access. The exhibitor has requested that some delegates for compliance reasons are not able to view this page.</p>
		<div class="buttons">
			<a class="button is-primary" @click="cancel()">OK</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'BoothBlockModal',
		data() {
			return {};
		},
		methods: {
			cancel() {
				this.$emit('close');
			},
		},
	};
</script>
