<template>
	<vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal--container" content-class="modal--content is-borderless mobile-booth-modal" v-on="$listeners">
		<div class="mobile-booth-modal__header">
			<img v-if="exhibitor.mobileLogo" :src="exhibitor.mobileLogo | imageUrl" />
			<div>
				<h1>{{ booth.title }}</h1>
				<h2 v-if="exhibitorGroup">{{ exhibitorGroup }}</h2>
				<span
					v-if="booth.floorPlanFloorItem && booth.floorPlanFloorItem.title"
					class="tag-track has-tooltip-bottom"
					data-tooltip="Download EAACI’s mobile app for more information about on-site exhibition"
					>{{ booth.floorPlanFloorItem.title }}</span
				>
			</div>
		</div>
		<div class="mobile-booth-modal__content">
			<VueMarkdown v-if="booth.description" :source="booth.description" :prerender="prerender" />
		</div>
		<a class="mobile-booth-modal__footer" href="https://www.eaaci.org/documents/congress/2022/mobile/qrcode.html" target="_blank">
			<inline-svg :src="require('../assets/mobile.svg')"></inline-svg>
			Download EAACI’s mobile app for more information about on-site exhibition
		</a>
	</vue-final-modal>
</template>

<script>
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';

	export default {
		name: 'MobileBoothModal',
		components: { VueMarkdown },
		props: {
			booth: {
				type: Object,
				required: true,
			},
			exhibitor: {
				type: Object,
				default: () => {},
			},
		},
		computed: {
			exhibitorGroup() {
				if (this.exhibitor.exhibitor_groups && this.exhibitor.exhibitor_groups.length > 0) {
					let temp = [...this.exhibitor.exhibitor_groups];
					temp.sort((a, b) => a.popup_display_order - b.popup_display_order);
					return temp[0].title;
				}
				return false;
			},
		},
		methods: {
			prerender: prerender,
		},
	};
</script>

<style scoped></style>
