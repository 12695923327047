<template>
	<div class="page exhibition">
		<h2 class="is-title is-inverted is-simple has-small-margin">{{ title }}</h2>
		<div class="header-background is-short" :style="{ backgroundImage: backgroundUrl }"></div>
		<div class="sponsors-wrapper">
			<ul v-if="hasGroups" class="exhibition-groups">
				<li :class="{ 'is-active': selectedGroup === 'all' }"><a @click="selectGroup('all')">All</a></li>
				<li v-for="group in exhibitorGroups" :class="{ 'is-active': selectedGroup === group.id }">
					<a @click="selectGroup(group.id)">{{ group.title }}</a>
					<div v-if="group && group.premium" class="star"><inline-svg :src="require('../assets/star.svg')"></inline-svg></div>
				</li>
			</ul>
			<div class="exhibitors exhibition-search-wrapper" :class="{ 'has-no-groups': !hasGroups }">
				<div class="exhibition-search field">
					<div class="agenda__search__input control has-icons-left">
						<span class="icon is-left">
							<inline-svg :src="require('../assets/search.svg')" width="32"></inline-svg>
						</span>
						<input v-model="debouncedSearch" class="input" type="text" placeholder="Search Exhibitor" />
					</div>
				</div>
				<div v-if="hasMobileBooths" class="exhibition-boothtype">
					<span :class="{ 'is-selected': boothType === false }">Digital</span>
					<input id="mobileBooth" v-model="boothType" type="checkbox" class="switch is-rounded is-large" />
					<label :class="{ 'is-selected': boothType === true }" for="mobileBooth">Physical</label>
				</div>
			</div>
			<VueMarkdown v-if="selectedGroup === 'all' && description" :source="description" class="exhibition-description content has-text-centered" :prerender="prerender" />
			<VueMarkdown v-else :source="activeGroup.description" class="exhibition-description content has-text-centered" :prerender="prerender" />

			<template v-if="boothType === false">
				<div v-if="filteredExhibitors.length > 0" class="exhibitors-items">
					<template v-for="fs in filteredExhibitors">
						<div v-if="fs.booths.length >= 1" :key="fs.id" class="exhibitors-item" :class="{ 'is-nas': fs.exhibitor_group && fs.exhibitor_group.nas }">
							<div v-if="isPremium(fs, selectedGroup)" class="star">
								<inline-svg :src="require('../assets/star.svg')" width="25"></inline-svg>
							</div>
							<a v-if="fs.booths.length > 1" @click.prevent="visitExhibitor(fs)">
								<img v-if="fs.logo && fs.logo.url" :src="fs.logo | imageUrl" :alt="fs.title" />
								<span v-if="!fs.logo || (fs.exhibitor_group && fs.exhibitor_group.nas)">{{ fs.title }}</span>
							</a>
							<a v-else-if="fs.booths.length === 1" @click.prevent="goToBooth(fs.booths[0])">
								<img v-if="fs.logo && fs.logo.url" :src="fs.logo | imageUrl" :alt="fs.title" />
								<span v-if="!fs.logo || (fs.exhibitor_group && fs.exhibitor_group.nas)">{{ fs.title }}</span>
							</a>
							<span v-else>
								<img v-if="fs.logo && fs.logo.url" :src="fs.logo | imageUrl" :alt="fs.title" />
								<span v-if="!fs.logo || (fs.exhibitor_group && fs.exhibitor_group.nas)">{{ fs.title }}</span>
							</span>
						</div>
					</template>
				</div>
			</template>
			<template v-if="boothType === true">
				<div v-if="filteredExhibitors.length > 0" class="exhibitors-items">
					<template v-for="fs in filteredExhibitors">
						<div v-if="fs.mobile_booths.length >= 1" :key="fs.id" class="exhibitors-item" :class="{ 'is-nas': fs.exhibitor_group && fs.exhibitor_group.nas }">
							<div v-if="isPremium(fs, selectedGroup)" class="star">
								<inline-svg :src="require('../assets/star.svg')" width="25"></inline-svg>
							</div>
							<a @click.prevent="showMobileBooth(fs.mobile_booths[0].url, fs)">
								<img v-if="fs.logo && fs.logo.url" :src="fs.logo | imageUrl" :alt="fs.title" />
								<span v-if="!fs.logo || (fs.exhibitor_group && fs.exhibitor_group.nas)">{{ fs.title }}</span>
							</a>
						</div>
					</template>
				</div>
			</template>
			<div class="exhibitors">
				<template v-if="filteredExhibitors && filteredExhibitors.length === 0">
					<div class="agenda__status is-full is-processing">
						<span class="icon"><inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg></span>
						<span>No exhibitors found with the current search.</span>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';
	import meta from '../utils/meta.js';

	import Link from '../components/Link';
	import BoothSelector from '../components/BoothSelector';
	import $http from '../utils/http.js';
	import platformSettings from '../utils/platformSettings';
	import ExhibitionOff from '../components/ExhibitionOff';
	import BoothBlockModal from '../components/BoothBlockModal';
	import MobileBoothModal from '@/components/MobileBoothModal';
	import ExternalDisclaimer from '@/components/ExternalDisclaimer';

	const debounce = (fn, delay) => {
		var timeoutID = null;
		return function() {
			clearTimeout(timeoutID);
			var args = arguments;
			var that = this;
			timeoutID = setTimeout(function() {
				fn.apply(that, args);
			}, delay);
		};
	};

	export default {
		name: 'Exhibition',
		components: { MobileBoothModal, Link, VueMarkdown },
		data() {
			return {
				type: 'exhibition',
				title: null,
				subtitle: null,
				description: null,
				background: null,
				search: '',
				debouncedSearch: '',
				allExhibitors: [],
				exhibitorGroups: [],
				selectedGroup: 'all',
				platformSettings: platformSettings.state,
				boothType: false,
			};
		},
		beforeRouteEnter(to, from, next) {
			let url = '/exhibition-hall';
			if (to.meta.type === 'nas') {
				url = '/nas-village';
			}
			$http.all([$http.get(url), $http.get(`exhibitor-groups`), $http.get(`exhibitors`)]).then(
				$http.spread((page, groups, exhibitors) => {
					next(vm => {
						vm.setType(to.meta.type);
						vm.setData(page);
						vm.setExhibitors(groups, exhibitors);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			let url = '/exhibition-hall';
			if (to.meta.type === 'nas') {
				url = '/nas-village';
			}
			$http.all([$http.get(url), $http.get(`exhibitor-groups`), $http.get(`exhibitors`)]).then(
				$http.spread((page, groups, exhibitors) => {
					this.setType(to.meta.type);
					this.setData(page);
					this.setExhibitors(groups, exhibitors);
					next();
				})
			);
		},
		computed: {
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
			filteredExhibitors() {
				if (this.selectedGroup === 'all') {
					return [...this.allExhibitors]
						.filter(e => {
							let retVal = true;

							if (this.search.length > 1) {
								let searchHit = false;
								if (searchHit === false && e.title && e.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) searchHit = true;
								if (searchHit === false) retVal = false;
							}

							return retVal;
						})
						.sort((a, b) => a.title.localeCompare(b.title));
				} else {
					let orderSort = false;

					let group = this.exhibitorGroups.find(e => e.id === this.selectedGroup);
					if (group) {
						if (group.premium) orderSort = true;
					}

					let exhibitors = this.allExhibitors
						.filter(e => {
							if (e.exhibitor_group) return e.exhibitor_group.id === this.selectedGroup;
							if (e.exhibitor_groups) return e.exhibitor_groups.some(e => e.id === this.selectedGroup);
						})
						.filter(e => {
							let retVal = true;

							if (this.search.length > 1) {
								let searchHit = false;
								if (searchHit === false && e.title && e.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) searchHit = true;
								if (searchHit === false) retVal = false;
							}

							return retVal;
						});

					if (orderSort) {
						return exhibitors.sort((x, y) => x.order - y.order);
					} else {
						return exhibitors.sort((a, b) => a.title.localeCompare(b.title));
					}
				}
			},
			activeGroup() {
				if (this.selectedGroup === 'all') return false;
				return this.exhibitorGroups.find(e => e.id === this.selectedGroup);
			},
			hasGroups() {
				return this.exhibitorGroups.length > 0;
			},
			hasMobileBooths() {
				return !!this.allExhibitors.find(e => e.mobile_booths?.length > 0);
			},
		},
		watch: {
			debouncedSearch: debounce(function(newVal) {
				this.search = newVal;
			}, 500),
		},
		methods: {
			setData(data) {
				this.title = data.data.title;
				this.subtitle = data.data.subtitle;
				this.background = data.data.background;
				this.description = data.data.description;
				if (this.type === 'nas') {
					this.societiesTitle = data.data.societies_title;
					this.societiesSubtitle = data.data.societies_subtitle;
				}
				meta.setTitle(this.title);
			},
			setExhibitors(groups, data) {
				this.exhibitorGroups = groups.data.sort((a, b) => {
					return a.order - b.order;
				});
				this.allExhibitors = data.data.map(e => {
					if (e.mobile_booth) {
						e.mobile_booth = e.mobile_booth.filter(e => e.type === 'physical');
					}
					return e;
				});
			},
			setType(type) {
				if (type) {
					this.type = type;
				}
			},
			selectGroup(group) {
				this.selectedGroup = group;
			},
			visitExhibitor(exhibitor) {
				exhibitor.booths.sort((a, b) => {
					return a.order - b.order;
				});
				this.$modal.show(
					BoothSelector,
					{
						exhibitor,
					},
					{
						classes: 'booth-selector',
						width: '80%',
						maxWidth: 1500,
						height: 'auto',
						adaptive: true,
					}
				);
			},
			goToBooth(booth) {
				if (this.platformSettings.exhibitionExchange === false) {
					this.$modal.show(
						ExhibitionOff,
						{},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 550,
							height: 'auto',
							adaptive: true,
						}
					);
				} else {
					if (booth.access === false) {
						this.$modal.show(
							BoothBlockModal,
							{},
							{
								classes: 'login-modal',
								width: '90%',
								maxWidth: 550,
								height: 'auto',
								adaptive: true,
							}
						);
					} else {
						if (booth.external_booth_url) {
							this.$modal.show(
								ExternalDisclaimer,
								{
									url: booth.external_booth_url,
									target: true,
								},
								{
									classes: 'external-disclaimer',
									width: '90%',
									maxWidth: 550,
									height: 'auto',
									adaptive: true,
								}
							);
						} else {
							this.$router.push({ name: 'Booth', params: { url: booth.url } });
						}
					}
				}
			},
			async showMobileBooth(boothId, exhibitor) {
				const booth = await $http.get(`/mobile-booths/${boothId}`);
				this.$vfm.show({
					component: MobileBoothModal,
					bind: {
						booth: booth.data,
						exhibitor,
					},
				});
			},
			isPremium(fs, group) {
				let retVal = false;
				let groupData = this.exhibitorGroups.find(e => e.id === group);
				if (group === 'all' || (groupData && !!groupData.premium === false)) {
					if (fs.exhibitor_group) retVal = !!fs.exhibitor_group.premium;
					if (fs.exhibitor_groups) retVal = fs.exhibitor_groups.some(e => !!e.premium === true);
				}
				return retVal;
			},
			prerender: prerender,
		},
	};
</script>
